import { default as editorQWh5Igg9pGMeta } from "/home/timo/community-management-system/@app/frontend/pages/editor.vue?macro=true";
import { default as forgot_45passwordPIhc3feFH8Meta } from "/home/timo/community-management-system/@app/frontend/pages/forgot-password.vue?macro=true";
import { default as imprint7zcz1T34OGMeta } from "/home/timo/community-management-system/@app/frontend/pages/imprint.vue?macro=true";
import { default as indexwTfoGXOvxpMeta } from "/home/timo/community-management-system/@app/frontend/pages/index.vue?macro=true";
import { default as loginBanVqoIZiUMeta } from "/home/timo/community-management-system/@app/frontend/pages/login.vue?macro=true";
import { default as privacycdwaoiVO0yMeta } from "/home/timo/community-management-system/@app/frontend/pages/privacy.vue?macro=true";
import { default as change_45passwordI2V81ioia8Meta } from "/home/timo/community-management-system/@app/frontend/pages/profile/change-password.vue?macro=true";
import { default as indexA2ntVlwH57Meta } from "/home/timo/community-management-system/@app/frontend/pages/profile/index.vue?macro=true";
import { default as registersbfkbdB1TlMeta } from "/home/timo/community-management-system/@app/frontend/pages/register.vue?macro=true";
import { default as reset_45passwordYVrH2Ep8onMeta } from "/home/timo/community-management-system/@app/frontend/pages/reset-password.vue?macro=true";
import { default as aboutrgRIecKPO2Meta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/about.vue?macro=true";
import { default as createG9lvJ2NRuAMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/items/create.vue?macro=true";
import { default as indexMJ5xIbrBNFMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/items/index.vue?macro=true";
import { default as materialsEUGlXRLKUtMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/materials.vue?macro=true";
import { default as membersfsykmAnjK2Meta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/members.vue?macro=true";
import { default as messagesoPIgVIkLd2Meta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/messages.vue?macro=true";
import { default as _91id_93mtAPVTnJsyMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/[id].vue?macro=true";
import { default as createuTBKEHv6xHMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/create.vue?macro=true";
import { default as indexbD2zV6vPdjMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/index.vue?macro=true";
import { default as injection_45keysdI4OQfjCGhMeta } from "/home/timo/community-management-system/@app/frontend/pages/rooms/injection-keys.ts?macro=true";
import { default as _91something_93qqZyvAAGE5Meta } from "/home/timo/community-management-system/@app/frontend/pages/test/[something].vue?macro=true";
import { default as editsVhVGdipyWMeta } from "/home/timo/community-management-system/@app/frontend/pages/topics/[...slug]/edit.vue?macro=true";
import { default as indexT1DICnL1NzMeta } from "/home/timo/community-management-system/@app/frontend/pages/topics/[...slug]/index.vue?macro=true";
import { default as createZ6z86Xea2UMeta } from "/home/timo/community-management-system/@app/frontend/pages/topics/create.vue?macro=true";
import { default as indexquMv8VafueMeta } from "/home/timo/community-management-system/@app/frontend/pages/topics/index.vue?macro=true";
import { default as uploadLD8sXFqU4RMeta } from "/home/timo/community-management-system/@app/frontend/pages/upload.vue?macro=true";
import { default as verifym8f7n5ugbMMeta } from "/home/timo/community-management-system/@app/frontend/pages/verify.vue?macro=true";
export default [
  {
    name: editorQWh5Igg9pGMeta?.name ?? "editor___de",
    path: editorQWh5Igg9pGMeta?.path ?? "/editor",
    meta: editorQWh5Igg9pGMeta || {},
    alias: editorQWh5Igg9pGMeta?.alias || [],
    redirect: editorQWh5Igg9pGMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/editor.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordPIhc3feFH8Meta?.name ?? "forgot-password___de",
    path: forgot_45passwordPIhc3feFH8Meta?.path ?? "/forgot-password",
    meta: forgot_45passwordPIhc3feFH8Meta || {},
    alias: forgot_45passwordPIhc3feFH8Meta?.alias || [],
    redirect: forgot_45passwordPIhc3feFH8Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: imprint7zcz1T34OGMeta?.name ?? "imprint___de",
    path: imprint7zcz1T34OGMeta?.path ?? "/imprint",
    meta: imprint7zcz1T34OGMeta || {},
    alias: imprint7zcz1T34OGMeta?.alias || [],
    redirect: imprint7zcz1T34OGMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: indexwTfoGXOvxpMeta?.name ?? "index___de",
    path: indexwTfoGXOvxpMeta?.path ?? "/",
    meta: indexwTfoGXOvxpMeta || {},
    alias: indexwTfoGXOvxpMeta?.alias || [],
    redirect: indexwTfoGXOvxpMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBanVqoIZiUMeta?.name ?? "login___de",
    path: loginBanVqoIZiUMeta?.path ?? "/login",
    meta: loginBanVqoIZiUMeta || {},
    alias: loginBanVqoIZiUMeta?.alias || [],
    redirect: loginBanVqoIZiUMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacycdwaoiVO0yMeta?.name ?? "privacy___de",
    path: privacycdwaoiVO0yMeta?.path ?? "/privacy",
    meta: privacycdwaoiVO0yMeta || {},
    alias: privacycdwaoiVO0yMeta?.alias || [],
    redirect: privacycdwaoiVO0yMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: change_45passwordI2V81ioia8Meta?.name ?? "profile-change-password___de",
    path: change_45passwordI2V81ioia8Meta?.path ?? "/profile/change-password",
    meta: change_45passwordI2V81ioia8Meta || {},
    alias: change_45passwordI2V81ioia8Meta?.alias || [],
    redirect: change_45passwordI2V81ioia8Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/profile/change-password.vue").then(m => m.default || m)
  },
  {
    name: indexA2ntVlwH57Meta?.name ?? "profile___de",
    path: indexA2ntVlwH57Meta?.path ?? "/profile",
    meta: indexA2ntVlwH57Meta || {},
    alias: indexA2ntVlwH57Meta?.alias || [],
    redirect: indexA2ntVlwH57Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: registersbfkbdB1TlMeta?.name ?? "register___de",
    path: registersbfkbdB1TlMeta?.path ?? "/register",
    meta: registersbfkbdB1TlMeta || {},
    alias: registersbfkbdB1TlMeta?.alias || [],
    redirect: registersbfkbdB1TlMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordYVrH2Ep8onMeta?.name ?? "reset-password___de",
    path: reset_45passwordYVrH2Ep8onMeta?.path ?? "/reset-password",
    meta: reset_45passwordYVrH2Ep8onMeta || {},
    alias: reset_45passwordYVrH2Ep8onMeta?.alias || [],
    redirect: reset_45passwordYVrH2Ep8onMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mtAPVTnJsyMeta?.name ?? "rooms-id___de",
    path: _91id_93mtAPVTnJsyMeta?.path ?? "/rooms/:id()",
    children: [
  {
    name: aboutrgRIecKPO2Meta?.name ?? "rooms-id-about___de",
    path: aboutrgRIecKPO2Meta?.path ?? "about",
    meta: aboutrgRIecKPO2Meta || {},
    alias: aboutrgRIecKPO2Meta?.alias || [],
    redirect: aboutrgRIecKPO2Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/about.vue").then(m => m.default || m)
  },
  {
    name: createG9lvJ2NRuAMeta?.name ?? "rooms-id-items-create___de",
    path: createG9lvJ2NRuAMeta?.path ?? "items/create",
    meta: createG9lvJ2NRuAMeta || {},
    alias: createG9lvJ2NRuAMeta?.alias || [],
    redirect: createG9lvJ2NRuAMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/items/create.vue").then(m => m.default || m)
  },
  {
    name: indexMJ5xIbrBNFMeta?.name ?? "rooms-id-items___de",
    path: indexMJ5xIbrBNFMeta?.path ?? "items",
    meta: indexMJ5xIbrBNFMeta || {},
    alias: indexMJ5xIbrBNFMeta?.alias || [],
    redirect: indexMJ5xIbrBNFMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/items/index.vue").then(m => m.default || m)
  },
  {
    name: materialsEUGlXRLKUtMeta?.name ?? "rooms-id-materials___de",
    path: materialsEUGlXRLKUtMeta?.path ?? "materials",
    meta: materialsEUGlXRLKUtMeta || {},
    alias: materialsEUGlXRLKUtMeta?.alias || [],
    redirect: materialsEUGlXRLKUtMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/materials.vue").then(m => m.default || m)
  },
  {
    name: membersfsykmAnjK2Meta?.name ?? "rooms-id-members___de",
    path: membersfsykmAnjK2Meta?.path ?? "members",
    meta: membersfsykmAnjK2Meta || {},
    alias: membersfsykmAnjK2Meta?.alias || [],
    redirect: membersfsykmAnjK2Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/members.vue").then(m => m.default || m)
  },
  {
    name: messagesoPIgVIkLd2Meta?.name ?? "rooms-id-messages___de",
    path: messagesoPIgVIkLd2Meta?.path ?? "messages",
    meta: messagesoPIgVIkLd2Meta || {},
    alias: messagesoPIgVIkLd2Meta?.alias || [],
    redirect: messagesoPIgVIkLd2Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id]/messages.vue").then(m => m.default || m)
  }
],
    meta: _91id_93mtAPVTnJsyMeta || {},
    alias: _91id_93mtAPVTnJsyMeta?.alias || [],
    redirect: _91id_93mtAPVTnJsyMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/[id].vue").then(m => m.default || m)
  },
  {
    name: createuTBKEHv6xHMeta?.name ?? "rooms-create___de",
    path: createuTBKEHv6xHMeta?.path ?? "/rooms/create",
    meta: createuTBKEHv6xHMeta || {},
    alias: createuTBKEHv6xHMeta?.alias || [],
    redirect: createuTBKEHv6xHMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/create.vue").then(m => m.default || m)
  },
  {
    name: indexbD2zV6vPdjMeta?.name ?? "rooms___de",
    path: indexbD2zV6vPdjMeta?.path ?? "/rooms",
    meta: indexbD2zV6vPdjMeta || {},
    alias: indexbD2zV6vPdjMeta?.alias || [],
    redirect: indexbD2zV6vPdjMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: injection_45keysdI4OQfjCGhMeta?.name ?? "rooms-injection-keys___de",
    path: injection_45keysdI4OQfjCGhMeta?.path ?? "/rooms/injection-keys",
    meta: injection_45keysdI4OQfjCGhMeta || {},
    alias: injection_45keysdI4OQfjCGhMeta?.alias || [],
    redirect: injection_45keysdI4OQfjCGhMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/rooms/injection-keys.ts").then(m => m.default || m)
  },
  {
    name: _91something_93qqZyvAAGE5Meta?.name ?? "test-something___de",
    path: _91something_93qqZyvAAGE5Meta?.path ?? "/test/:something()",
    meta: _91something_93qqZyvAAGE5Meta || {},
    alias: _91something_93qqZyvAAGE5Meta?.alias || [],
    redirect: _91something_93qqZyvAAGE5Meta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/test/[something].vue").then(m => m.default || m)
  },
  {
    name: editsVhVGdipyWMeta?.name ?? "topics-slug-edit___de",
    path: editsVhVGdipyWMeta?.path ?? "/topics/:slug(.*)*/edit",
    meta: editsVhVGdipyWMeta || {},
    alias: editsVhVGdipyWMeta?.alias || [],
    redirect: editsVhVGdipyWMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/topics/[...slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexT1DICnL1NzMeta?.name ?? "topics-slug___de",
    path: indexT1DICnL1NzMeta?.path ?? "/topics/:slug(.*)*",
    meta: indexT1DICnL1NzMeta || {},
    alias: indexT1DICnL1NzMeta?.alias || [],
    redirect: indexT1DICnL1NzMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/topics/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: createZ6z86Xea2UMeta?.name ?? "topics-create___de",
    path: createZ6z86Xea2UMeta?.path ?? "/topics/create",
    meta: createZ6z86Xea2UMeta || {},
    alias: createZ6z86Xea2UMeta?.alias || [],
    redirect: createZ6z86Xea2UMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/topics/create.vue").then(m => m.default || m)
  },
  {
    name: indexquMv8VafueMeta?.name ?? "topics___de",
    path: indexquMv8VafueMeta?.path ?? "/topics",
    meta: indexquMv8VafueMeta || {},
    alias: indexquMv8VafueMeta?.alias || [],
    redirect: indexquMv8VafueMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/topics/index.vue").then(m => m.default || m)
  },
  {
    name: uploadLD8sXFqU4RMeta?.name ?? "upload___de",
    path: uploadLD8sXFqU4RMeta?.path ?? "/upload",
    meta: uploadLD8sXFqU4RMeta || {},
    alias: uploadLD8sXFqU4RMeta?.alias || [],
    redirect: uploadLD8sXFqU4RMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/upload.vue").then(m => m.default || m)
  },
  {
    name: verifym8f7n5ugbMMeta?.name ?? "verify___de",
    path: verifym8f7n5ugbMMeta?.path ?? "/verify",
    meta: verifym8f7n5ugbMMeta || {},
    alias: verifym8f7n5ugbMMeta?.alias || [],
    redirect: verifym8f7n5ugbMMeta?.redirect || undefined,
    component: () => import("/home/timo/community-management-system/@app/frontend/pages/verify.vue").then(m => m.default || m)
  }
]