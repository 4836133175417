import revive_payload_client_YTGlmQWWT5 from "/home/timo/community-management-system/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/timo/community-management-system/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/timo/community-management-system/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/timo/community-management-system/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/timo/community-management-system/@app/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/timo/community-management-system/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_l138bYv0gX from "/home/timo/community-management-system/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_N1rIkEnC9Q from "/home/timo/community-management-system/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_dOTnCc6TUQ from "/home/timo/community-management-system/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/home/timo/community-management-system/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/timo/community-management-system/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import update_now_lH4mbKQJoh from "/home/timo/community-management-system/@app/frontend/plugins/update-now.ts";
import urql_FlK1jaV38U from "/home/timo/community-management-system/@app/frontend/plugins/urql.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_l138bYv0gX,
  composition_N1rIkEnC9Q,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  update_now_lH4mbKQJoh,
  urql_FlK1jaV38U
]